import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

const LANG_VERSION = '1.0.2';

const loadPath = () => {
    // const searchedStr = 'f' + 'o' + 'c' + 'u' + 's.b' + 'i';
    const searchedStr = 'f' + 'o' + 'c' + 'u' + 'stech.x' + 'y' + 'z';
    let arr = window.location.host.split('.');
    arr.shift();

    let default_admin_domain = arr.join('.');
    if (window.location.host === 'localhost:3000') {
        default_admin_domain = searchedStr;
    } else if (window.location.host === 'localhost:3333') {
        default_admin_domain = 'mallsense.ae';
    }

    const result = `https://admin.${default_admin_domain}/locales/{{lng}}/{{ns}}.json`;
    return result;
};

function i18Init() {
    i18n.use(ChainedBackend)
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            // debug: true,
            preload: ['ru', 'en'],
            // saveMissing: true,
            backend: {
                crossDomain: true,
                loadPath,

                backends: [LocalStorageBackend, HttpBackend],
                backendOptions: [
                    {
                        expirationTime: 1 * 24 * 60 * 60 * 1000, // 1 day,
                        loadPath: '/locales/{{lng}}/{{ns}}.json',
                        versions: {
                            en: LANG_VERSION,
                            ru: LANG_VERSION,
                            kk: LANG_VERSION,
                            ar: LANG_VERSION,
                            uk: LANG_VERSION,
                        },
                    },
                    {
                        expirationTime: 1 * 24 * 60 * 60 * 1000, // 1 day,
                        loadPath,
                        versions: {
                            en: LANG_VERSION,
                            ru: LANG_VERSION,
                            kk: LANG_VERSION,
                            ar: LANG_VERSION,
                            uk: LANG_VERSION,
                        },
                    },
                ],
            },

            nsSeparator: false,
            keySeparator: false,
            ns: [
                'translationHelp',
                'translation',
                'translationEvents',
                'appClientReports',
                'translationCategories',
                'policies',
                'metrics',
            ],
            lng: 'en',
            fallbackLng: 'en',
            defaultNS: 'translation',

            // other i18next options
        });
}

export default i18Init;
