import styled from 'styled-components/macro';

export const TitleWrapper = styled.div<{ isRtlLanguage: Boolean }>`
    position: relative;
    width: 100%;
    /* margin-top: var(--space-xs); */
    height: 44px;
    /* border-bottom: 1px solid var(--color-contrast-low); */
    max-width: 100%;
    column-gap: 15px;
    display: flex;
    justify-content: space-between;
`;
