import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { TTF } from '../interfaces';
import { convertDateToISO } from './convertDateToISO';

export const getInfo = (data: string[][]): { objIds: number[]; timeFreq: TTF } => {
    const datesArr = data.map((row) => {
        return row[2];
    });
    const sorted = sortBy(datesArr, (item) => DateTime.fromISO(item).toMillis());
    let timeFreq: TTF = 'MS';
    const dayNumbers = Array.from(new Set(sorted.map((item) => DateTime.fromISO(convertDateToISO(item)).day)));

    if (dayNumbers.length > 1 || dayNumbers[0] !== 1) {
        timeFreq = 'D';
    }

    const objIds = Array.from(new Set(data.map((item) => Number(item[0]))));

    return { objIds, timeFreq };
};
